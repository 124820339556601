<ng-container *ngIf="group">
  <div class="group-connector">
    <button size="m" (click)="toggleOperator()" appearance="mono" tuiButton>
      {{
        (group.operator === LogicalOperator.and
          ? 'segmentEditor.simpleFilter.operatorAnd'
          : 'segmentEditor.simpleFilter.operatorOr'
        ) | translate
      }}
    </button>
    <button tuiIconButton appearance="icon" (click)="removeSelf()" icon="tuiIconTrash"></button>
  </div>
  <div class="items">
    <div class="item-in-group" *ngFor="let item of group.items; trackBy: trackByIndex; let index = index">
      <knk-simple-filter-condition-control
        *ngIf="isCondition(item)"
        [condition]="item"
        (conditionChange)="updateGroupItem(index, $event)"
        [definition]="getConditionDef(item.definitionId)!"
        [disabled]="disabled"
        (remove)="removeGroupItem(index)"
      ></knk-simple-filter-condition-control>

      <knk-simple-filter-condition-group
        *ngIf="isGroup(item)"
        [group]="item"
        [conditionBlocks]="conditionBlocks"
        (remove)="removeGroupItem(index)"
        (groupChange)="updateGroupItem(index, $event)"
        [disabled]="disabled"
      ></knk-simple-filter-condition-group>
    </div>

    <tui-error [error]="error ? (error | translate) : null"></tui-error>

    <knk-simple-filter-condition-dropdown
      [canGroupOptions]="true"
      *ngIf="!disabled"
      [conditionBlocks]="conditionBlocks"
      (applyConditions)="addItemsToGroup($event.items, $event.groupWith)"
    ></knk-simple-filter-condition-dropdown>
  </div>
</ng-container>
