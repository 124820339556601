import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { createContextLogger } from '@konnektu/helpers';
import { TuiButtonModule } from '@taiga-ui/core';
import { DynamicComponent, DynamicIoDirective } from 'ng-dynamic-component';
import { BirthDateControlComponent } from '../controls/birth-date-control.component';
import { CheckboxControlComponent } from '../controls/checkbox-control.component';
import { CommunicationResponseControlComponent } from '../controls/communication-response/communication-response-control.component';
import { DateControlComponent } from '../controls/date-control.component';
import { LookupControlComponent } from '../controls/lookup-control.component';
import { LookupRemoteControlComponent } from '../controls/lookup-remote-control.component';
import { NullableBooleanControlComponent } from '../controls/nullable-boolean-control.component';
import { NumberControlComponent } from '../controls/number-control.component';
import { NumberRangeControlComponent } from '../controls/number-range-control.component';
import { StringControlComponent } from '../controls/string-control.component';
import { ConditionDef, CustomConditionDef } from '../models';
import { SimpleFilterCondition } from '../simple-filter.component';

@Component({
  selector: 'knk-simple-filter-condition-control',
  templateUrl: 'condition-control.component.html',
  styleUrls: ['condition-control.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    DynamicComponent,
    DynamicIoDirective,
    CheckboxControlComponent,
    NullableBooleanControlComponent,
    StringControlComponent,
    LookupControlComponent,
    LookupRemoteControlComponent,
    DateControlComponent,
    BirthDateControlComponent,
    NumberRangeControlComponent,
    NumberControlComponent,
    CommunicationResponseControlComponent
  ]
})
export class ConditionControlComponent {
  private readonly logger = createContextLogger('ConditionControlComponent');

  @Input() condition: SimpleFilterCondition | null = null;

  @Output() conditionChange = new EventEmitter<SimpleFilterCondition>();

  @Input() definition: ConditionDef | null = null;

  @Input() disabled = false;

  @Input() canRemove = true;

  @Output() remove = new EventEmitter<void>();

  isCustomControl(def: ConditionDef): def is CustomConditionDef {
    return typeof def.type === 'function';
  }

  updateState(newState: any) {
    if (this.condition) {
      this.logger.debug('Update state', newState);
      this.condition.state = newState;
      this.conditionChange.emit(this.condition);
    }
  }

  updateExpression(newExpression: any) {
    if (this.condition) {
      this.condition.expression = newExpression;
      this.conditionChange.emit(this.condition);
    }
  }

  removeSelf() {
    this.remove.emit();
  }
}
