import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  LogicalOperator,
  MetastoreOperator,
  MetastoreService,
  and,
  from,
  startsWith
} from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import {
  TUI_DEFAULT_MATCHER,
  TuiFilterPipeModule,
  TuiForModule,
  TuiLetModule,
  TuiMatcher
} from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TuiInputModule, TuiMultiSelectModule } from '@taiga-ui/kit';
import { sortBy } from 'lodash-es';
import {
  BehaviorSubject,
  debounceTime,
  map,
  shareReplay,
  startWith,
  switchMap
} from 'rxjs';
import { LookupRemoteConditionDef } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

@Component({
  selector: 'knk-simple-filter-lookup-remote-control',
  template: `
    <tui-multi-select
      [ngModel]="state"
      [disabled]="disabled"
      [valueContent]="
        'segmentEditor.simpleFilter.controls.lookupAnyOf'
          | translate: { count: state?.length || 0 }
      "
      [editable]="false"
      (ngModelChange)="updateState($event)"
      [tuiTextfieldCleaner]="true"
    >
      {{ definition.label | translate }}
      <ng-template tuiDataList>
        <tui-input
          tuiTextfieldIconLeft="tuiIconSearchLarge"
          class="tui-space_all-2"
          [tuiTextfieldLabelOutside]="true"
          [ngModel]="search$.value"
          (ngModelChange)="search$.next($event)"
        >
          {{
            'segmentEditor.simpleFilter.controls.lookupSearchInputLabel'
              | translate
          }}
        </tui-input>
        <tui-data-list
          *ngIf="items$ | async as items; else loading"
          tuiMultiSelectGroup
        >
          <button *ngFor="let item of items" tuiOption [value]="item.Id">
            {{ item.Name | translate }}
          </button>
        </tui-data-list>
        <ng-template #loading>
          <tui-loader class="tui-space_vertical-4"></tui-loader>
        </ng-template>
      </ng-template>
    </tui-multi-select>
  `,
  styles: [
    `
      :host {
        display: flex;
      }
      tui-multi-select {
        width: 300px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiForModule,
    TuiMultiSelectModule,
    TuiDropdownModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiInputModule,
    TuiFilterPipeModule
  ]
})
export class LookupRemoteControlComponent
  extends SimpleFilterControl<LookupRemoteConditionDef>
  implements OnInit
{
  private readonly metastore = inject(MetastoreService);

  search$ = new BehaviorSubject('');

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || []);
  }

  readonly filterMatcher: TuiMatcher<{ Id: number; Name: string }> = (
    item,
    search: string
  ) => TUI_DEFAULT_MATCHER(item.Name, search);

  items$ = this.search$.pipe(
    debounceTime(300),
    switchMap((search) => this.getItems(search).pipe(startWith(null)))
  );

  getItems(search: string) {
    const request = from(this.definition.entityName)
      .select(['Id', 'Name'])
      .paginate(0, 100);
    if (search.length > 2) {
      if (this.definition.additionalFilter) {
        request.where(
          and(this.definition.additionalFilter, startsWith('Name', search))
        );
      } else {
        request.where(startsWith('Name', search));
      }
    }
    if (this.definition.additionalFilter) {
      request.where(this.definition.additionalFilter);
    }
    return this.metastore
      .select<{ Id: number; Name: string }>(request.done())
      .pipe(
        shareReplay(1),
        map((values) => values.filter((v) => v.Name)),
        map((items) => sortBy(items, (i) => !this.state?.includes(i.Id)))
      );
  }

  parseState(state: null | number[]) {
    return state;
  }

  protected updateState(state: number[]) {
    if (!state.length) {
      this.updateStateAndExpression(null);
    } else {
      this.updateStateAndExpression(state);
    }
  }

  createExpression(column: string, state: number[] | null): object {
    if (!state) {
      return {};
    }
    if (state.length > 1) {
      return {
        [LogicalOperator.or]: state.map((v) => ({
          [column]: { [MetastoreOperator.eq]: v }
        }))
      };
    }
    return {
      [column]: { [MetastoreOperator.eq]: state[0] }
    };
  }
}
