import { Component, inject } from '@angular/core';
import { DialogComponent } from '@konnektu/components';
import { DestroyService } from '@konnektu/helpers';
import {
  DataType,
  MetastoreQuerySource,
  MetastoreService,
  extractObjectParams,
  getTableMetaByPath
} from '@konnektu/metastore';
import { EditSegmentRequest } from '@konnektu/segments-data';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  map,
  shareReplay,
  takeUntil
} from 'rxjs';

@Component({
  selector: 'knk-segment-edit-dialog',
  templateUrl: 'edit-dialog.component.html',
  styleUrls: ['edit-dialog.component.scss'],
  providers: [DestroyService]
})
export class SegmentEditDialogComponent extends DialogComponent<EditSegmentRequest | null> {
  private readonly metastore = inject(MetastoreService);
  private readonly destroy$ = inject(DestroyService);

  name?: string;

  from$ = new BehaviorSubject<string | null>(null);

  fields: string[] = [];

  filterExpression: Record<string, any> | null = null;

  currentVersion: number | null = null;

  isIndexUnique = false;

  indexedColumns: string[] = [];

  metadataProvider$ = this.metastore
    .metadata({ ui: { section: 'SegmentEditDialogComponent' } })
    .pipe(shareReplay(1));

  fromTableOptions$ = this.metadataProvider$.pipe(
    map((tables) => tables.map((dto) => ({ label: dto.Name, value: dto.Name })))
  );

  changeSelectedProperties(newProps: string[]): void {
    this.fields = newProps;
  }

  cancel(): void {
    this.closeSelf(null);
  }

  done(): void {
    const fromValue = this.from$.getValue();
    if (!fromValue || !this.filterExpression) {
      return;
    }

    const { obj, parameters } = extractObjectParams(this.filterExpression);

    if (this.currentVersion === null) {
      forkJoin(
        this.fields
          .filter((f) => this.indexedColumns.includes(f))
          .map((f) =>
            getTableMetaByPath(
              this.metadataProvider$,
              fromValue,
              f.split('.')
            ).pipe(
              filter((meta) => typeof meta === 'number'),
              map((meta) => ({ meta, field: f }))
            )
          )
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((tableOrFieldMeta) => {
          const indexColumns = tableOrFieldMeta.map(({ meta, field }) => ({
            name: field,
            type: meta as DataType
          }));
          this.closeSelf({
            name: this.name,
            query: {
              text: {
                query: {
                  $from: this.from$.getValue(),
                  $select: this.fields,
                  $where: obj
                },
                parameters
              },
              source: MetastoreQuerySource.Postgres
            },
            index: {
              columns: indexColumns,
              unique: this.isIndexUnique
            }
          });
        });
    } else {
      forkJoin(
        this.fields
          .filter((f) => this.indexedColumns.includes(f))
          .map((f) =>
            getTableMetaByPath(
              this.metadataProvider$,
              fromValue,
              f.split('.')
            ).pipe(
              filter((meta) => typeof meta === 'number'),
              map((meta) => ({ meta, field: f }))
            )
          )
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.closeSelf({
            name: this.name,
            query: {
              text: {
                query: {
                  $from: this.from$.getValue(),
                  $select: this.fields,
                  $where: obj
                },
                parameters
              },
              source: MetastoreQuerySource.Postgres
            }
          });
        });
    }
  }
}
