import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  LogicalOperator,
  MetastoreOperator,
  MetastoreService,
  from
} from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import {
  TUI_DEFAULT_MATCHER,
  TuiFilterPipeModule,
  TuiLetModule,
  TuiMatcher,
  tuiPure
} from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TuiInputModule, TuiMultiSelectModule } from '@taiga-ui/kit';
import { sortBy } from 'lodash-es';
import { map, shareReplay } from 'rxjs';
import { LookupConditionDef } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

@Component({
  selector: 'knk-simple-filter-lookup-control',
  template: `
    <tui-multi-select
      [ngModel]="state"
      [disabled]="disabled"
      [valueContent]="
        'segmentEditor.simpleFilter.controls.lookupAnyOf'
          | translate: { count: state?.length || 0 }
      "
      [editable]="false"
      (ngModelChange)="updateState($event)"
      [tuiTextfieldCleaner]="true"
    >
      {{ definition.label | translate }}
      <ng-container *tuiDataList>
        <tui-input
          tuiTextfieldIconLeft="tuiIconSearchLarge"
          class="tui-space_all-2"
          #input
          [tuiTextfieldLabelOutside]="true"
          [(ngModel)]="search"
        >
          {{
            'segmentEditor.simpleFilter.controls.lookupSearchInputLabel'
              | translate
          }}
          <input tuiTextfield type="text" />
        </tui-input>
        <tui-data-list
          *tuiLet="getAllItems() | async as items"
          tuiMultiSelectGroup
        >
          <button
            *ngFor="let item of items || [] | tuiFilter: filterMatcher : search"
            tuiOption
            [value]="item.Id"
          >
            {{ item.Name | translate }}
          </button>
        </tui-data-list>
      </ng-container>
    </tui-multi-select>
  `,
  styles: [
    `
      :host {
        display: flex;
      }
      tui-multi-select {
        width: 300px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiMultiSelectModule,
    TuiLetModule,
    TuiDropdownModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiLetModule,
    TuiInputModule,
    TuiFilterPipeModule
  ]
})
export class LookupControlComponent
  extends SimpleFilterControl<LookupConditionDef>
  implements OnInit
{
  private readonly metastore = inject(MetastoreService);

  search = '';

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || []);
  }

  readonly filterMatcher: TuiMatcher<{ Id: number; Name: string }> = (
    item,
    search: string
  ) => TUI_DEFAULT_MATCHER(item.Name, search);

  @tuiPure
  getAllItems() {
    const request = from(this.definition.entityName).select(['Id', 'Name']);
    if (this.definition.additionalFilter) {
      request.where(this.definition.additionalFilter);
    }
    return this.metastore
      .select<{ Id: number; Name: string }>(request.done())
      .pipe(
        shareReplay(1),
        map((values) => values.filter((v) => v.Name)),
        map((items) => sortBy(items, (i) => !this.state?.includes(i.Id)))
      );
  }

  parseState(state: null | number[]) {
    return state;
  }

  protected updateState(state: number[]) {
    if (!state.length) {
      this.updateStateAndExpression(null);
    } else {
      this.updateStateAndExpression(state);
    }
  }

  createExpression(column: string, state: number[] | null): object {
    if (!state) {
      return {};
    }
    if (state.length > 1) {
      return {
        [LogicalOperator.or]: state.map((v) => ({
          [column]: { [MetastoreOperator.eq]: v }
        }))
      };
    }
    return {
      [column]: { [MetastoreOperator.eq]: state[0] }
    };
  }
}
