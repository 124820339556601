import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SegmentTypeIds } from '@konnektu/domain-models';
import {
  MetastoreComponentsModule,
  MetastoreService
} from '@konnektu/metastore';
import { SEGMENT_TYPE_TO_TRANSLATION } from '@konnektu/segments-data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  TUI_DEFAULT_MATCHER,
  TuiContextWithImplicit,
  TuiDestroyService,
  TuiFilterPipeModule,
  TuiForModule,
  TuiLetModule,
  TuiStringHandler,
  TuiStringMatcher,
  tuiPure
} from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListComponent,
  TuiDataListModule,
  TuiDialogContext,
  TuiGroupModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiNotificationModule,
  TuiPrimitiveTextfieldModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
  tuiIsEditingKey
} from '@taiga-ui/core';
import {
  TuiBreadcrumbsModule,
  TuiInputModule,
  TuiRadioBlockModule,
  TuiSelectModule,
  TuiTabsModule
} from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { map, shareReplay, startWith, takeUntil } from 'rxjs';
import { SegmentFieldSelectorComponent } from './field-selector/field-selector.component';
import { SimpleFilterTemplate } from './simple-filter/models';
import { SimpleFilterComponent } from './simple-filter/simple-filter.component';
import { SimpleFilterTemplateStore } from './simple-filter/template-store';

export type SegmentEditorDialogData =
  | {
      form: SegmentEditorComponent['form']['value'];
      canEditFieldsAndExpression: boolean;
      simpleFilterState?: { templateId: string } & any;
    }
  | undefined;

export type SegmentEditorDialogResult = {
  simpleFilterState?: {
    templateId: string;
  } & any;
  form: SegmentEditorComponent['form']['value'] & {
    typeId: SegmentTypeIds | null;
  };
} | null;

export const SEGMENT_EMPTY_TYPE = 'no-type' as const;

@Component({
  selector: 'knk-segment-editor',
  templateUrl: 'segment-editor.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['segment-editor.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    TuiInputModule,
    TuiLinkModule,
    TuiBreadcrumbsModule,
    TuiGroupModule,
    TuiRadioBlockModule,
    TuiSelectModule,
    TuiDataListModule,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule,
    TuiFilterPipeModule,
    TuiForModule,
    TuiNotificationModule,
    TuiLoaderModule,
    TuiLetModule,
    TuiButtonModule,
    TuiTabsModule,
    TuiScrollbarModule,
    MetastoreComponentsModule,
    SegmentFieldSelectorComponent,
    SimpleFilterComponent
  ],
  providers: [TuiDestroyService]
})
export class SegmentEditorComponent implements OnInit {
  private readonly fb = inject(FormBuilder);

  private readonly metastore = inject(MetastoreService);

  private readonly translate = inject(TranslateService);

  private readonly destroy$ = inject(TuiDestroyService);

  private readonly templates = inject(SimpleFilterTemplateStore).templates;

  protected readonly context =
    inject<
      TuiDialogContext<SegmentEditorDialogResult, SegmentEditorDialogData>
    >(POLYMORPHEUS_CONTEXT);

  readonly form = this.fb.group({
    name: this.fb.nonNullable.control('', Validators.required),
    entityName: this.fb.control<string | null>(null, [Validators.required]),
    typeId: this.fb.control<SegmentTypeIds | null>(null),
    selectedFields: this.fb.nonNullable.control<string[]>([]),
    disabledFields: this.fb.nonNullable.control<string[]>([]),
    indexedFields: this.fb.nonNullable.control<string[]>([]),
    isIndexUnique: this.fb.nonNullable.control(false),
    expression: this.fb.control<object | null>(null)
  });

  readonly metadataProvider$ = this.metastore
    .metadata({
      ui: { section: 'NewSegmentPage' }
    })
    .pipe(shareReplay(1));

  protected readonly metadataProvider = toSignal(this.metadataProvider$);

  readonly entityNameItems$ = this.metadataProvider$.pipe(
    map((tables) =>
      tables.map((t) => ({
        value: t.Name,
        caption: this.translate.instant(t.Name)
      }))
    )
  );

  protected readonly selectedEntityName = toSignal(
    this.form.controls.entityName.valueChanges.pipe(
      startWith(this.form.controls.entityName.value || null)
    ),
    { requireSync: true }
  );

  get selectedFields() {
    return this.form.controls.selectedFields.value;
  }

  get indexedFields() {
    return this.form.controls.indexedFields.value;
  }

  protected readonly selectedEntityMetadata = computed(() => {
    const metadataProvider = this.metadataProvider();
    const entityName = this.selectedEntityName();
    if (!metadataProvider || !entityName) {
      return null;
    }
    return metadataProvider.find((t) => t.Name === entityName);
  });

  protected readonly isCreatingSegment = !this.context.data;

  protected readonly SegmentType = SegmentTypeIds;

  protected readonly SEGMENT_EMPTY_TYPE = SEGMENT_EMPTY_TYPE;

  simpleFilterState: any = null;

  searchEntityName = '';

  activeTabIndex = 0;

  selectedSimpleFilterTemplate: SimpleFilterTemplate | null = null;

  fieldsForFieldSelector: {
    field: string;
    indexed: boolean;
    disabled?: boolean;
  }[] = [];

  canSwitchModes = true;

  canEditFieldsAndExpression =
    this.context.data?.canEditFieldsAndExpression ?? true;

  readonly filterEntityName: TuiStringMatcher<{
    value: string;
    caption: string;
  }> = (item, search: string) => TUI_DEFAULT_MATCHER(item.caption, search);

  protected readonly selectedSegmentType = toSignal(
    this.form.controls.typeId.valueChanges.pipe(
      startWith(this.form.controls.typeId.value),
      map((typeId) => (typeId ? typeId : SEGMENT_EMPTY_TYPE))
    )
  );

  protected readonly isSegmentTypeSelected = computed(() => {
    const currentType = this.selectedSegmentType();
    return currentType !== SEGMENT_EMPTY_TYPE;
  });

  ngOnInit() {
    this.form.valueChanges.subscribe((value) => {
      this.fieldsForFieldSelector =
        value.selectedFields?.map((f) => ({
          field: f,
          indexed: value.indexedFields?.includes(f) ?? false,
          disabled: value.disabledFields?.includes(f) ?? false
        })) || ([] as { field: string; indexed: boolean }[]);
    });

    if (this.context.data) {
      this.canSwitchModes = false;
      if (this.context.data?.simpleFilterState) {
        const { templateId, ...simpleFilterState } =
          this.context.data.simpleFilterState;
        this.selectedSimpleFilterTemplate =
          this.templates.find((t) => t.id === templateId) ?? null;
        this.simpleFilterState = simpleFilterState;
      } else {
        this.activeTabIndex = 1;
      }
      this.form.patchValue(this.context.data.form);

      this.form.controls.isIndexUnique.setValue(
        this.context.data.form.isIndexUnique as boolean
      );
    }

    this.form.controls.entityName.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.controls.expression.patchValue(null);
        this.form.controls.selectedFields.setValue([]);
        this.form.controls.indexedFields.setValue([]);
      });
  }

  @tuiPure
  stringify(
    items: readonly { value: string; caption: string }[]
  ): TuiStringHandler<TuiContextWithImplicit<string>> {
    const valueNameMap = new Map(
      items.map(({ value, caption }) => [value, caption] as [string, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<string>) =>
      valueNameMap.get($implicit) || '';
  }

  @tuiPure
  protected stringifySegmentType(
    context: TuiContextWithImplicit<SegmentTypeIds | null>
  ) {
    return this.translate.instant(
      SEGMENT_TYPE_TO_TRANSLATION.get(
        context.$implicit ? context.$implicit : null
      ) ?? 'segmentType.noType'
    );
  }

  onArrowDown<T>(list: TuiDataListComponent<T>, event: Event): void {
    list.onFocus(event, true);
  }

  onKeyDown(key: string, element: HTMLElement | null): void {
    if (element && tuiIsEditingKey(key)) {
      element.focus({ preventScroll: true });
    }
  }

  onStateChange(newState: any) {
    this.simpleFilterState = newState;
  }

  changeMode(index: number) {
    this.activeTabIndex = index;
  }

  updateExpression(expression: object | null) {
    this.form.controls.expression.patchValue(expression);
  }

  updateColumns(
    columns: Partial<{
      fields: Partial<{ field: string; indexed: boolean }>[];
      isIndexUnique: boolean;
    }>
  ) {
    this.form.controls.selectedFields.setValue([]);
    this.form.controls.indexedFields.setValue([]);
    columns.fields?.forEach((c) => {
      if (c.field) {
        this.form.controls.selectedFields.setValue([
          ...this.form.controls.selectedFields.value,
          c.field
        ]);
        if (c.indexed) {
          this.form.controls.indexedFields.setValue([
            ...this.form.controls.indexedFields.value,
            c.field
          ]);
        }
      }
    });
    this.form.controls.isIndexUnique.setValue(columns?.isIndexUnique ?? false);
  }

  updateUniqueIndex(unique: boolean) {
    this.form.controls.isIndexUnique.patchValue(unique);
  }

  updateFromTemplate(template: SimpleFilterTemplate) {
    this.selectedSimpleFilterTemplate = template;

    this.form.controls.indexedFields.setValue([]);
    this.form.controls.selectedFields.setValue([]);
    this.form.controls.isIndexUnique.setValue(template.isIndexUnique ?? false);
    this.form.controls.entityName.patchValue(template.entityName ?? false);

    template.columns.forEach((c) => {
      if (c.column) {
        this.form.controls.selectedFields.setValue([
          ...this.form.controls.selectedFields.value,
          c.column
        ]);
        if (c.index) {
          this.form.controls.indexedFields.setValue([
            ...this.form.controls.indexedFields.value,
            c.column
          ]);
        }
      }
    });
  }

  selectSegmentType(type: SegmentTypeIds | typeof SEGMENT_EMPTY_TYPE) {
    switch (type) {
      case SegmentTypeIds.User:
        this.form.controls.typeId.setValue(SegmentTypeIds.User);
        this.form.controls.entityName.setValue('User');
        this.form.controls.selectedFields.setValue(['Id']);
        this.form.controls.indexedFields.setValue(['Id']);
        this.form.controls.disabledFields.setValue(['Id']);
        this.form.controls.isIndexUnique.setValue(true);
        break;
      case SegmentTypeIds.Cookie:
      case 'no-type':
        this.form.controls.typeId.setValue(null);
        this.form.controls.entityName.setValue(null);
        this.form.controls.selectedFields.setValue([]);
        this.form.controls.indexedFields.setValue([]);
        this.form.controls.disabledFields.setValue([]);
        this.form.controls.isIndexUnique.setValue(false);
        break;
    }
  }

  onDone() {
    if (
      this.simpleFilterState &&
      this.selectedSimpleFilterTemplate &&
      this.activeTabIndex === 0
    ) {
      this.context.completeWith({
        simpleFilterState: {
          templateId: this.selectedSimpleFilterTemplate.id,
          ...this.simpleFilterState
        },
        form: this.form.getRawValue()
      });
    } else {
      this.context.completeWith({ form: this.form.getRawValue() });
    }
  }

  onCancel() {
    this.context.completeWith(null);
  }
}
