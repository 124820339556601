import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform,
  inject,
  input
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { Reference, TableFullDto } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiSvgModule
} from '@taiga-ui/core';
import {
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiDataListDropdownManagerModule
} from '@taiga-ui/kit';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { map, startWith } from 'rxjs';
import { FieldSelectorField } from './models';

@Pipe({
  name: 'split',
  standalone: true
})
export class SplitPipe implements PipeTransform {
  transform(val: string, divider: string): string[] {
    return val.split(divider);
  }
}

@Component({
  selector: 'knk-segment-field-selector',
  templateUrl: 'field-selector.component.html',
  styleUrls: ['field-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TuiCheckboxBlockModule,
    TuiCheckboxLabeledModule,
    TuiButtonModule,
    TuiHostedDropdownModule,
    TuiActiveZoneModule,
    TuiDataListDropdownManagerModule,
    TuiDataListModule,
    TuiDropdownModule,
    TuiSvgModule,
    PolymorpheusModule,
    SplitPipe
  ]
})
export class SegmentFieldSelectorComponent {
  private readonly fb = inject(FormBuilder);

  @Input() tableMetadata!: TableFullDto;

  @Input() tables!: TableFullDto[];

  @Input() disabled = false;

  @Input() set isIndexUnique(val: boolean) {
    this.form.controls.isIndexUnique.setValue(val);
  }

  @Output() columnsChanged = new EventEmitter<typeof this.form.value>();

  @Input() set fields(val: FieldSelectorField[]) {
    this.form.controls.fields.clear();
    val.forEach((field) =>
      this.form.controls.fields.push(
        this.fb.group({
          field: this.fb.nonNullable.control(field.field),
          indexed: this.fb.nonNullable.control(field.indexed),
          disabled: this.fb.nonNullable.control(field.disabled ?? false)
        })
      )
    );
  }

  readonly isIndexUniqueVisible = input(true);

  readonly isColumnIndexVisible = input(true);

  readonly form = this.fb.group({
    fields: this.fb.nonNullable.array<
      FormGroup<{
        field: FormControl<string>;
        indexed: FormControl<boolean>;
        disabled: FormControl<boolean>;
      }>
    >([]),
    isIndexUnique: this.fb.nonNullable.control(false)
  });

  isDropdownOpen = false;

  protected readonly isAtLeastOneFieldSelected = toSignal(
    this.form.controls.fields.valueChanges.pipe(
      startWith(this.form.value.fields ?? []),
      map((fields) => fields.length > 0)
    )
  );

  isColumnSelected(columnName: string, rootPath?: string) {
    return this.form.controls.fields.value
      .map((f) => f.field)
      .includes(rootPath ? `${rootPath}.${columnName}` : columnName);
  }

  selectColumn(columnName: string, rootPath?: string) {
    this.form.controls.fields.push(
      this.fb.group({
        field: this.fb.nonNullable.control(
          rootPath ? `${rootPath}.${columnName}` : columnName
        ),
        disabled: this.fb.nonNullable.control(false),
        indexed: this.fb.nonNullable.control(true)
      })
    );
    this.columnsChanged.emit(this.form.value);
  }

  setColumnIndexed(indexed: boolean, index: number) {
    this.form.controls.fields.at(index).patchValue({ indexed });
    this.columnsChanged.emit(this.form.value);
  }

  removeColumn(index: number) {
    this.form.controls.fields.removeAt(index);
    this.columnsChanged.emit(this.form.value);
  }

  updateIsIndexUnique(unique: boolean) {
    this.form.controls.isIndexUnique.setValue(unique);
    this.columnsChanged.emit(this.form.value);
  }

  getTarget(prop: Reference) {
    const target = this.tables.find((t) => t.Name === prop.TargetTable);
    return target;
  }
}
